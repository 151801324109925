<template>
  <div class="container">
    <Header />
    <img class="banner" src="../../assets/responsibility-banner1.jpg" />
    <van-tabs
      v-model="active"
      line-width="3rem"
      animated
      swipeable
      color="#de006f"
    >
      <van-tab title="公益动态" class="dongtai">
        <router-link
          :to="'/newsdetail/' + item.newsID"
          class="item"
          v-for="(item, index) in welfareData"
          :key="index"
        >
          <div class="item-left">
            <van-image
              width="8rem"
              height="4rem"
              :src="imgUrl + item.yImgPath"
            />
          </div>
          <div class="item-right">
            <div class="item-title">{{ item.title }}</div>
            <div class="item-detail">
              <div class="item-date">
                {{
                  dateFormat("YYYY-mm-dd", item.ReleaseTime.substring(6, 19))
                }}
              </div>
              <div class="item-more">查看详情&nbsp;→</div>
            </div>
          </div>
        </router-link>
      </van-tab>
      <van-tab title="公益基金会" class="hujijin">
        <div class="content">
          <p style="text-indent: 1.8rem">
            金天国际积极投身社会公益事业。现拥有一个公益基金会——“护基金”及两个专项基金，即中国健康促进基金会生命质量管理专项基金、山东公安优抚基金民警健康促进基金，围绕捐助贫困残障儿童的“护苗计划”、捐助基层公安民警的“护盾计划”、“乡村振兴”及“社会公益赞助”全面践行企业社会责任，将“关爱生命、奉献社会”的理念落到实处。为更多的生命加油，让更多人的生活充满活力。
          </p>
          <img class="img" src="../../assets/hujijin-img1.jpg" />
        </div>
      </van-tab>
      <van-tab title="公益护苗" class="humiao">
        <div class="content">
          <!-- <p style="text-indent: 1.8rem">
            孩子是祖国未来，民族的希望，儿童的健康成长，关系国家的前途命运。守护儿童工作，做好儿童保障，是一个民族企业的社会责任与担当。护苗行动，我们一直在践行...
          </p> -->
          <p>
            ●
            2012年9月，携手山东省妇联共同发起“捐建百所留守儿童活动站”的春苗工程；
          </p>
          <p>
            ●
            2015年5月，发起“结对一个孩子，温暖千万颗心”工程，资助特殊困境儿童；
          </p>
          <p>
            ●
            2016年10月，为共青团夏津县委爱心捐赠100万元用于贫困残障儿童的帮助；
          </p>
          <p>● 2017年5月，捐助100万设立夏津公益助学基金；再次献爱烟台市特殊教育学校；</p>
         
          <p>
            ●
            2017年8月，携手江苏宿迁湖滨新区经济发展局开展“扶贫助学”圆梦公益活动；“为爱前行，点亮希望每盏灯”公益助学行走进江苏宿迁；
          </p>
         
          <p>● 2017年9月，“为爱前行，点亮希望每盏灯”走进张家界特殊教育学校；</p>
          <p>● 2018年，继续深化“护苗计划”，捐助烟台市特殊教育学校；</p>
          <p>● 2019年5月，持续献爱烟台市特殊教育学校，为结对孩子捐赠善款。</p>
          <img class="img" src="../../assets/humiao-img.jpg" />
        </div>
      </van-tab>
      <van-tab title="公益护盾" class="hudun">
        <div class="content">
          <!-- <p style="text-indent: 1.8rem">
            金天国际累计发起捐赠1800万元专项基金，用于开展公安英模和基层公安民警的健康休整，伤残民警的康复治疗，健康知识讲座、心里干预、健康风险评估等活动，充分发挥民族企业在凝聚警心、鼓舞斗志上的重要作用。该基金自成立以来，截止目前，已举办超40期的健康休整活动，近3000位民警参与，护盾行动，我们一直在践行...
          </p> -->
          <p>● 2015年1月，向山东省公安民警优抚基金会捐赠800万元；</p>
          <p>● 2016年12月，向山东省公安民警优抚基金会追加捐赠1000万元；</p>
          <p>
            ●
            2017年4月，金天国际医疗科技——山东公安民警职业健康促进基金2017年开班仪式成功举办；
          </p>
          <p>● 2017年4月，首期、第二期休整班在江苏太湖举办 ；</p>
          <p>● 2017年5月，第三期休整班在江苏宿迁举办；</p>
          <p>● 2017年6月，第四期、第五期休整班在河北北戴河举办;</p>
          <p>
            ●
            2018年，发起成立离退休军人休养基地、设立山东公安优抚基金——民警健康休养基地。
          </p>
          <img class="img" src="../../assets/hudun-img.jpg" />
        </div>
      </van-tab>
      <van-tab title="乡村振兴" class="fupin">
        <div class="content">
          <!-- <p style="text-indent: 1.8rem">
            大爱无言，上善若水，金天国际多年来持续发起扶贫公益行动，贯彻国家共同奔赴小康的大政方针，体现的不仅是承载的民族基因，更是企业家的炙热情怀。扶贫行动，我们一直在践行...
          </p> -->
          <p>
            ●
            2017年1月，为江苏泗洪县陈圩乡祖姚村村里低保户困难孤寡老人送去慰问金和慰问品；
          </p>
          <p>
            ●
            2017年8月，分阶段帮助新疆维吾尔自治区库车县阿拉哈格镇扶贫攻坚工作，先期投入资金帮扶100户贫困户脱贫；
          </p>
          <p>
            ●
            2017年11月，走进湖南省怀化市溆浦县淘金坪乡令溪塘村，捐资50万元用于精准扶贫帮困；
          </p>
          <p>● 2018年，助力“扶贫车间”新模式，助推全面小康。</p>
          <!-- <img class="img" src="../../assets/fupin-img.jpg" /> -->
        </div>
      </van-tab>
      <van-tab title="社会公益赞助" class="zanzhu">
        <div class="content">
          <!-- <p style="text-indent: 1.8rem">
            公益是一项崇高的事业，是呼唤爱心无私助人的高尚精神。当我们用心去触摸公益，它会让我知道什么是朴素和善良。公益赞助行动，我们一直在践行...
          </p> -->
          <p>● 2017年4月，金天国际春姿倾城烟台首届大型公益旗袍秀成功举办；</p>
          <p>● 2017年7月，金天国际杯“红绿灯下的感动”公益活动全面启动；</p>
          <p>● 2017年9月，金天国际捐助30万元助力簰州湾“八一”文化广场建设；</p>
          <p>● 2017年10月，金天国际助力宿迁首届少儿书法大赛公益拍卖会；</p>
          <p>● 2017年10月，金天国际助力宿迁首届少儿书法大赛公益拍卖会和“关注健康女性、关爱两癌母亲大型公益活动；</p>
         
          <p>● 2017年12月，“金天国际杯·中国宿迁2017冬泳节邀请赛”成功举办；</p>
          <p>● 2018年6月，金天国际独家冠名LUREPRO路亚黄金联赛成功举办；</p>
          <p>
            ● 2019年1月，“金天国际杯”中国·宿迁首届盆景书画名家作品展成功举办；
          </p>
          <p>
            ●
            2019年5月，金天国际立足护基金的深化，公益助力海阳革命老区道路修建；
          </p>
          <p>
            ●
            2020年2月至3月，金天国际组织捐赠价值近300万元生态养护物资、现金，助力一线抗击新冠疫情。
          </p>
          <p>
            ●
            2020年4月7日，金天国际捐赠3.7万只口罩，助力福山区教育教学工作顺利展开。仅2020年上半年，金天国际组织捐赠价值近300万元生殖养护物资、现金及服务，助力一线抗击新冠疫情。后持续为烟台市教育机构、宿迁市捐赠口罩、现金等
          </p>
          <p>
            ●
            2022年3月18日，金天国际基金会向烟台市福山区慈善总会捐赠了价值40.5万元的雪莲养护贴产品和防护口罩。
            </p>
          <p>
            ●
            2022年4月7日-4月18日，金天国际公益基金会向烟台福山区各小学的14472个孩子捐赠了42840只口罩。
           </p>
          <p>
            ●
            2022年9月9日，金天国际公益基金会联合宿迁市慈善总会共同向宿迁市城市管理局捐赠5万只日常防护口罩。
           </p>
          <!-- <img class="img" src="../../assets/zanzhu-img.jpg" /> -->
        </div>
      </van-tab>
    </van-tabs>
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import baseURL from "../../utils/config";
export default {
  data() {
    return {
      active: 0,
      welfareData: [],
      imgUrl: baseURL.imageUrl,
    };
  },
  components: {
    Header,
    Footer,
  },
  mounted() {
    this.active = Number(this.$route.params.tabIndex);
    this.getWelfare();
  },
  watch: {
    "$route.path": function () {
      this.active = Number(this.$route.params.tabIndex);
    },
  },
  methods: {
    getWelfare() {
      this.$api.getWelfare().then((res) => {
        this.welfareData = res;
      });
    },
    dateFormat(fmt, date) {
      date = new Date(Number(date));
      let ret;
      const opt = {
        "Y+": date.getFullYear().toString(), // 年
        "m+": (date.getMonth() + 1).toString(), // 月
        "d+": date.getDate().toString(), // 日
        "H+": date.getHours().toString(), // 时
        "M+": date.getMinutes().toString(), // 分
        "S+": date.getSeconds().toString(), // 秒
        // 有其他格式化字符需求可以继续添加，必须转化成字符串
      };
      for (let k in opt) {
        ret = new RegExp("(" + k + ")").exec(fmt);
        if (ret) {
          fmt = fmt.replace(
            ret[1],
            ret[1].length == 1 ? opt[k] : opt[k].padStart(ret[1].length, "0")
          );
        }
      }
      return fmt;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  .banner {
    width: 100%;
    margin-top: 3.5rem;
  }
  p {
    text-align: justify;
  }
  .dongtai {
    .item {
      height: 6rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: dashed #eee 0.1rem;
      .item-left {
        padding-left: 0.5rem;
      }
      .item-right {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        .item-title {
          font-size: 1rem;
          font-weight: bold;
          color: #5b5b5b;
          line-height: 1.2rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .item-detail {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 0.5rem;
          justify-content: space-between;
          .item-date {
            color: #9b9b9b;
            font-size: 0.9rem;
          }
          .item-more {
            color: #9b9b9b;
            font-size: 0.9rem;
          }
        }
      }
    }
  }
  .hujijin {
    padding: 0 1rem;
    .content {
      color: #666666;
      letter-spacing: 0.05rem;
      line-height: 1.5rem;
      .img {
        width: 100%;
      }
    }
  }
  .humiao {
    padding: 0 1rem;
    .content {
      color: #666666;
      letter-spacing: 0.05rem;
      line-height: 1.5rem;
      .img {
        width: 100%;
      }
    }
  }
  .hudun {
    padding: 0 1rem;
    .content {
      color: #666666;
      letter-spacing: 0.05rem;
      line-height: 1.5rem;
      .img {
        width: 100%;
      }
    }
  }
  .fupin {
    padding: 0 1rem;
    .content {
      color: #666666;
      letter-spacing: 0.05rem;
      line-height: 1.5rem;
      .img {
        width: 100%;
      }
    }
  }
  .zanzhu {
    padding: 0 1rem;
    .content {
      color: #666666;
      letter-spacing: 0.05rem;
      line-height: 1.5rem;
      .img {
        width: 100%;
      }
    }
  }
}
</style>